import {z} from 'zod';
import validator from 'validator';

const emailSchema = (message: string) => z.string().email({message});

const phoneSchema = (message: string) =>
	z.string().refine(validator.isMobilePhone, {message});

const emailSearchSchema = emailSchema(
	'The email format is invalid. Check and edit your input before searching again.',
);
const emailForceActivationSchema = emailSchema('The email format is invalid.');

const phoneSearchSchema = phoneSchema(
	'The phone number format is invalid. Check and edit your input before searching again.',
);

const validateInputWithSchema = (input: string, schema: z.ZodSchema) => {
	if (input === '') {
		return '';
	}
	const result = schema.safeParse(input.trim());
	return result.success ? '' : result.error.issues[0].message;
};

export const validateSearchInput = (input: string) => {
	const isPhoneNumber = /^\+?\d/.test(input.trim());
	const schema = isPhoneNumber ? phoneSearchSchema : emailSearchSchema;
	return validateInputWithSchema(input, schema);
};

export const validateForceActivationInput = (input: string) => {
	return validateInputWithSchema(input, emailForceActivationSchema);
};
