import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	Heading,
	Text,
	Box,
	Button,
} from '@tempo/core';

interface UnlinkModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

const UnlinkModal: React.FC<UnlinkModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent padding="spacing.3xl" data-testid="unlink-modal">
				<Heading
					overflow="hidden"
					color="text.neutral.primary.default"
					textOverflow="ellipsis"
					variant="heading.l"
					fontStyle="normal"
					fontWeight={700}
					marginBottom="spacing.l"
				>
					Unlink this account
				</Heading>
				<Text
					marginBottom="spacing.xl"
					overflow="hidden"
					color="text.neutral.secondary.default"
					textOverflow="ellipsis"
					fontStyle="normal"
					fontWeight={400}
				>
					You are about to unlink the subscriber ID from his offer.
				</Text>
				<Box display="flex" justifyContent="end">
					<Button variant="ghost" mr="spacing.s" onClick={onClose}>
						Cancel
					</Button>
					<Button colorScheme="blue" onClick={onConfirm}>
						Confirm unlink
					</Button>
				</Box>
			</ModalContent>
		</Modal>
	);
};

export default UnlinkModal;
