import React from 'react';
import {Box} from '@tempo/core';
import Search from '@components/Search';
import {withAuth} from '@hocs/withAuth';

function Index() {
	return (
		<>
			<Box display="flex" height="100vh">
				<Box
					flex={1}
					px="spacing.l"
					py="spacing.m"
					display="flex"
					justifyContent="center"
					overflow="auto"
				>
					<Search />
				</Box>
			</Box>
		</>
	);
}

export default withAuth(Index);
