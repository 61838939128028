import React from 'react';

type Props = {
	url?: string;
	width?: number;
	height?: number;
	dataTestId?: string;
};
export const Shape: React.FC<Props> = (props) => {
	const {url, width = 360, height = 316, dataTestId} = props;
	return (
		<svg
			data-testid={dataTestId}
			width={width}
			height={height}
			viewBox="0 0 360 316"
			preserveAspectRatio="xMidYMid meet"
			fill="white"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g clipPath="url(#clipPath)">
				<mask
					id="mask"
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="360"
					height="316"
				>
					<path
						d="M360 316H8.39839C4.30047 298.275 -11.8863 227.166 15.9796 217.479C38.2722 209.729 81.5463 256.159 100.397 280.343C68.8427 176.669 73.7602 115.042 100.397 105.767C121.706 98.3472 152.167 123.561 164.734 137.096C159.612 112.569 143.835 17.9641 184.609 3.12414C217.228 -8.74783 271.553 44.2088 294.638 72.1712C291.36 34.4529 297.917 6.00968 316.562 0.856916C331.478 -3.26529 351.736 8.48301 360 14.8724V316Z"
						fill="#D9D9D9"
					/>
				</mask>
				<g mask="url(#mask)">
					<path d="M537.507 -7H-35.9004V317H537.507V-7Z" fill="url(#pattern)" />
				</g>
			</g>
			<defs>
				<pattern
					id="pattern"
					patternContentUnits="objectBoundingBox"
					width="1"
					height="1"
				>
					<use
						xlinkHref="#image"
						transform="matrix(0.000244141 0 0 0.000432074 -0.02 0.00225067)"
					/>
				</pattern>
				<clipPath id="clipPath">
					<rect width="360" height="316" fill="white" />
				</clipPath>
				<image id="image" width="4096" height="2304" xlinkHref={url} />
			</defs>
		</svg>
	);
};
