import {useUser} from '@auth0/nextjs-auth0/client';
import React from 'react';
import {Box, Button, Heading, Text} from '@tempo/core';
import {DeezerPartnersLogo} from '@components/DeezerPartnersLogo';
import {Shape} from '@components/Shape';
import useWindowSize from '@hooks/useWindowSize';
import Link from 'next/link';

const getSizePerResolution = (size: number, baseWidth: number): number => {
	if (typeof window === 'undefined') {
		return size;
	}
	const scaleFactor = Math.min(window.innerWidth / baseWidth);
	const newSize = size * scaleFactor;
	return Math.round(newSize * 1e4) / 1e4;
};

export function withAuth<P extends object>(
	WrappedComponent: React.ComponentType<P>,
): React.FC<P> {
	return function WithAuth(props: P) {
		const {user, error, isLoading} = useUser();
		const {width, height} = useWindowSize();
		const minHeight = height && height <= 800 ? `${height}px` : '800px';

		if (isLoading) return <div>Loading...</div>;
		if (error) return <div>{error.message}</div>;

		if (user) {
			return <WrappedComponent {...props} />;
		}

		const images = ['/bg1.webp', '/bg2.webp', '/bg3.webp', '/bg4.webp'];

		return (
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				height="100vh"
				width="100vw"
			>
				<Box
					height="100vh"
					minHeight={minHeight}
					overflow="hidden"
					position="relative"
					width="full"
				>
					<Box
						p={{base: 'spacing.l', md: 'spacing.3xl'}}
						display="flex"
						flexDir="column"
						height="100vh"
						width="full"
					>
						<Box flexShrink={0}>
							<DeezerPartnersLogo
								dataTestId="deezer-for-partners-logo"
								width={width && width <= 430 ? 116 : 232}
								height={width && width <= 430 ? 33 : 66}
							/>
						</Box>
						<Box
							pt={{
								base: 'spacing.3xl',
								sm: 'spacing.3xl',
								md: 'spacing.2xl',
								lg: 'spacing.2xl',
							}}
							display="flex"
							flexDir="column"
							height="100%"
							width="full"
							justifyContent={{base: 'start', md: 'space-between'}}
						>
							<Box
								display="flex"
								flexDir="column"
								gap="spacing.xl"
								width="full"
							>
								<Heading
									as="h2"
									maxWidth="474px"
									wordBreak="keep-all"
									data-testid="page-header-text"
									fontFamily="Deezer Product"
									fontSize={{
										base: '2.56rem',
										sm: '2.56rem',
										md: '5.6rem',
										lg: '5.6rem',
									}}
									fontWeight={{
										base: '700',
										sm: '700',
										md: '700',
										lg: '700',
									}}
									lineHeight={{
										base: '1.92rem',
										sm: '1.92rem',
										md: '6.4rem',
										lg: '6.4rem',
									}}
									textAlign={{
										base: 'left',
										sm: 'left',
										md: 'left',
										lg: 'left',
									}}
									paddingTop={{
										base: 0,
										sm: 'spacing.xl',
									}}
								>
									Manage
									<br />
									your music services
								</Heading>
								<Button
									as="a"
									href="/api/auth/login"
									size="lg"
									width="fit-content"
									alignSelf="flex-start"
									data-testid="login-button"
								>
									Log in
								</Button>
							</Box>
							<Link href="/legal">
								<Text
									data-testid="legal-notice"
									pt="spacing.2xl"
									variant="body.l.underlined"
									fontSize="1.12rem"
									fontWeight={400}
									lineHeight="0.8rem"
								>
									Legal notice
								</Text>
							</Link>
						</Box>
					</Box>
					<Box position="absolute" paddingTop={4} right={-0.5} bottom={-0.5}>
						<Shape
							dataTestId="login-page-image"
							url={images[Math.floor(Math.random() * images.length)]}
							width={getSizePerResolution(
								360,
								width && width <= 430 ? 320 : 640,
							)}
							height={getSizePerResolution(
								316,
								width && width <= 430 ? 320 : 640,
							)}
						/>
					</Box>
				</Box>
			</Box>
		);
	};
}
