import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	Heading,
	Text,
	Button,
} from '@tempo/core';
import {CheckCircleFilledIcon} from '@tempo/icons';

interface ConfirmationModalProps {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	subtitle: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
	isOpen,
	onClose,
	title,
	subtitle,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				padding="spacing.3xl"
				display="flex"
				flexDirection="column"
				alignItems="center"
				data-testid="confirmation-modal"
			>
				<CheckCircleFilledIcon
					color="icon.feedback.success.default"
					boxSize="64px"
					marginBottom="spacing.l"
				/>
				<Heading
					overflow="hidden"
					color="text.neutral.primary.default"
					textOverflow="ellipsis"
					variant="heading.l"
					fontStyle="normal"
					fontWeight={700}
					marginBottom="spacing.l"
				>
					{title}
				</Heading>
				<Text
					marginBottom="spacing.xl"
					overflow="hidden"
					color="text.neutral.secondary.default"
					textOverflow="ellipsis"
					fontStyle="normal"
					fontWeight={400}
				>
					{subtitle}
				</Text>
				<Button variant="outline" mr="spacing.s" onClick={onClose}>
					Close
				</Button>
			</ModalContent>
		</Modal>
	);
};

export default ConfirmationModal;
